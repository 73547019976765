<template>
  <b-card>
    <client-form @submited="form_submited"></client-form>
  </b-card>

</template>

<script>
 
import {
  BTable, BButton, BFormGroup, BAvatar, BBadge, BCardBody,BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ClientForm from '@/components/ClientForm'


export default {
  components: {
    BCard,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    ClientForm
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modes: ['multi', 'single', 'range'],
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
        {
          1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
        }],

      selected: [],

    }
  },
  created() {
    let _this = this;
    this.$store.dispatch('clientsModule/fetchClients').then(function(response){
      _this.items = response.data;
    })
  },
  methods: {
    form_submited(client){
      this.$router.push({'name' : 'clients'})
    }
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
